import { createClient } from '@supabase/supabase-js';
import type { Database } from './database.types';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL || '';
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY || '';

// Create Supabase client with proper configuration
export const supabase = createClient<Database>( 
  supabaseUrl,
  supabaseAnonKey,
  {
    auth: {
      persistSession: true,
      autoRefreshToken: true,
      detectSessionInUrl: false
    },
    db: {
      schema: 'public'
    },
    realtime: {
      params: {
        eventsPerSecond: 2
      }
    },
    global: {
      headers: {
        'X-Client-Info': 'supabase-js/2.39.7'
      }
    }
  }
);

// Helper function to check if Supabase is properly configured
export const isSupabaseConfigured = () => {
  return Boolean(supabaseUrl && supabaseAnonKey);
};

// Helper function to test Supabase connection with retries
export const testSupabaseConnection = async (retryCount = 0, maxRetries = 3) => {
  try {
    // Check if credentials exist
    if (!supabaseUrl || !supabaseAnonKey) {
      console.error('Supabase credentials not found');
      return false;
    }

    // Test connection with a simple query
    const { data, error } = await supabase
      .from('markers')
      .select('id')
      .limit(1)
      .maybeSingle();
    
    if (error) {
      throw error;
    }

    return true;
  } catch (err) {
    console.error('Supabase connection error:', {
      attempt: retryCount + 1,
      error: err instanceof Error ? err.message : err,
      url: supabaseUrl
    });
    
    // Retry on network errors with exponential backoff
    if (retryCount < maxRetries && err instanceof Error && 
        (err.message.includes('Failed to fetch') || 
         err.message.includes('NetworkError') ||
         err.message.includes('upstream connect error'))) {
      const delay = Math.min(1000 * Math.pow(2, retryCount), 8000); // Exponential backoff capped at 8s
      await new Promise(resolve => setTimeout(resolve, delay));
      return testSupabaseConnection(retryCount + 1, maxRetries);
    }
    
    return false;
  };
};