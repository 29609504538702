import React from 'react';

const ShieldFistIcon = ({ size = 24, className = '' }) => (
  <svg 
    width={size} 
    height={size} 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
    className={className}
  >
    <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z" />
    <path d="M12 13V8" />
    <path d="M12 13c1.5 0 2.5-1 2.5-2.5V8c0-1.5-1-2.5-2.5-2.5S9.5 6.5 9.5 8v2.5c0 1.5 1 2.5 2.5 2.5z" />
    <path d="M12 13v3" />
  </svg>
);

export default ShieldFistIcon;