import React, { useState, useRef, useEffect } from 'react';
import { AlertTriangle, Chrome, Mic, Loader2, Square } from 'lucide-react';
import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: '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',
  dangerouslyAllowBrowser: true
});

interface WhisperMultibrowserTranscriberProps {
  language?: 'en' | 'es';
}

const WhisperMultibrowserTranscriber = ({ language = 'en' }: WhisperMultibrowserTranscriberProps) => {
  const [isRecording, setIsRecording] = useState(false);
  const [englishText, setEnglishText] = useState('');
  const [spanishText, setSpanishText] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isTranslating, setIsTranslating] = useState(false);
  const [isFirefox] = useState(() => navigator.userAgent.toLowerCase().includes('firefox'));
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const chunksRef = useRef<Blob[]>([]);

  const checkMicrophonePermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ 
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true
        }
      });
      
      stream.getTracks().forEach(track => track.stop());
      setHasPermission(true);
      setError(null);
      return true;
    } catch (err: any) {
      console.error('Microphone permission error:', err);
      setHasPermission(false);
      
      if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
        setError(language === 'es' 
          ? 'Acceso al micrófono denegado. Por favor, cierre y vuelva a abrir la página, luego intente de nuevo.'
          : 'Microphone access denied. Please close and reopen the page, then try again.');
      } else if (err.name === 'NotFoundError' || err.name === 'DevicesNotFoundError') {
        setError(language === 'es'
          ? 'No se encontró micrófono. Por favor, conecte un micrófono e intente de nuevo.'
          : 'No microphone found. Please connect a microphone and try again.');
      } else {
        setError(language === 'es'
          ? 'No se puede acceder al micrófono. Por favor, verifique la configuración de su dispositivo.'
          : 'Unable to access microphone. Please check your device settings.');
      }
      return false;
    }
  };

  const translateText = async (text: string) => {
    try {
      setIsTranslating(true);
      setEnglishText(text);

      const response = await openai.chat.completions.create({
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content: "You are a professional translator. Translate the given English text to Spanish, maintaining a natural and conversational tone."
          },
          {
            role: "user",
            content: text
          }
        ],
        temperature: 0.3,
      });

      const translation = response.choices[0].message.content;
      if (translation) {
        setSpanishText(translation);
      }
    } catch (err) {
      console.error('Translation error:', err);
      setError(language === 'es' 
        ? 'Error en la traducción. Por favor, intente de nuevo.'
        : 'Translation failed. Please try again.');
    } finally {
      setIsTranslating(false);
    }
  };

  const convertToWav = async (audioBlob: Blob): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const fileReader = new FileReader();

      fileReader.onload = async (e) => {
        try {
          const arrayBuffer = e.target?.result as ArrayBuffer;
          const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
          
          // Create WAV file
          const wavBuffer = audioBufferToWav(audioBuffer);
          const wavBlob = new Blob([wavBuffer], { type: 'audio/wav' });
          resolve(wavBlob);
        } catch (err) {
          reject(err);
        }
      };

      fileReader.onerror = (err) => reject(err);
      fileReader.readAsArrayBuffer(audioBlob);
    });
  };

  const audioBufferToWav = (buffer: AudioBuffer): ArrayBuffer => {
    const numOfChan = buffer.numberOfChannels;
    const length = buffer.length * numOfChan * 2;
    const sampleRate = buffer.sampleRate;
    const result = new ArrayBuffer(44 + length);
    const view = new DataView(result);

    // WAV header
    writeString(view, 0, 'RIFF');
    view.setUint32(4, 36 + length, true);
    writeString(view, 8, 'WAVE');
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, numOfChan, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * 2 * numOfChan, true);
    view.setUint16(32, numOfChan * 2, true);
    view.setUint16(34, 16, true);
    writeString(view, 36, 'data');
    view.setUint32(40, length, true);

    // Write audio data
    const offset = 44;
    const channels = [];
    for (let i = 0; i < numOfChan; i++) {
      channels.push(buffer.getChannelData(i));
    }

    let pos = 0;
    while (pos < buffer.length) {
      for (let i = 0; i < numOfChan; i++) {
        const sample = Math.max(-1, Math.min(1, channels[i][pos]));
        view.setInt16(offset + (pos * numOfChan + i) * 2, sample < 0 ? sample * 0x8000 : sample * 0x7FFF, true);
      }
      pos++;
    }

    return result;
  };

  const writeString = (view: DataView, offset: number, string: string) => {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  };

  const transcribeAudio = async (audioBlob: Blob) => {
    try {
      // Convert to WAV format
      const wavBlob = await convertToWav(audioBlob);
      
      // Create file from WAV blob
      const audioFile = new File([wavBlob], 'audio.wav', { type: 'audio/wav' });

      const transcription = await openai.audio.transcriptions.create({
        file: audioFile,
        model: "whisper-1",
        language: "en",
        response_format: "text"
      });

      if (transcription) {
        setEnglishText(transcription);
        await translateText(transcription);
      }
    } catch (err) {
      console.error('Transcription error:', err);
      setError(language === 'es'
        ? 'Error al transcribir el audio. Por favor, intente de nuevo.'
        : 'Failed to transcribe audio. Please try again.');
    }
  };

  const startRecording = async () => {
    const hasAccess = await checkMicrophonePermission();
    if (!hasAccess) return;

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      chunksRef.current = [];

      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunksRef.current.push(e.data);
        }
      };

      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(chunksRef.current, { type: 'audio/webm' });
        await transcribeAudio(audioBlob);
      };

      mediaRecorder.start(1000);
      setIsRecording(true);
      setError(null);
    } catch (err) {
      console.error('Recording error:', err);
      setError(language === 'es'
        ? 'Error al iniciar la grabación. Por favor, verifique los permisos del micrófono.'
        : 'Error starting recording. Please check your microphone permissions.');
      setIsRecording(false);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
    }
    setIsRecording(false);
  };

  return (
    <div className="bg-black/30 backdrop-blur-sm rounded-lg p-6 min-h-[200px]">
      <div className="space-y-4">
        {isFirefox && (
          <div className="bg-blue-900/50 text-blue-100 px-4 py-3 rounded-lg">
            <div className="flex items-center gap-2 mb-2">
              <Chrome size={20} className="flex-shrink-0" />
              <span className="font-medium">
                {language === 'es' ? 'Usando OpenAI Whisper' : 'Using OpenAI Whisper'}
              </span>
            </div>
            <p className="text-sm">
              {language === 'es'
                ? 'Usando OpenAI Whisper para reconocimiento de voz preciso en todos los navegadores.'
                : 'Using OpenAI Whisper for accurate speech recognition across all browsers.'}
            </p>
          </div>
        )}

        {error && (
          <div className="bg-red-900/50 text-red-100 px-4 py-3 rounded-lg mb-4 flex items-center gap-2">
            <AlertTriangle size={20} className="flex-shrink-0" />
            <span>{error}</span>
          </div>
        )}

        <div className="bg-black/30 backdrop-blur-sm rounded-lg p-4">
          <div className="text-sm font-medium text-gray-400 mb-2">English:</div>
          <p className="text-gray-100 min-h-[2.5rem]">
            {englishText || (isRecording ? 'Recording...' : 'Click record to start')}
          </p>
        </div>

        <div className="bg-black/30 backdrop-blur-sm rounded-lg p-4">
          <div className="text-sm font-medium text-gray-400 mb-2">Español:</div>
          <p className="text-gray-100 min-h-[2.5rem]">
            {isTranslating ? (
              <span className="flex items-center gap-2 text-gray-400">
                <Loader2 size={16} className="animate-spin" />
                Traduciendo...
              </span>
            ) : (
              spanishText || (isRecording ? 'Grabando...' : 'Haga clic en grabar para comenzar')
            )}
          </p>
        </div>

        <div className="flex justify-center mt-6">
          <button
            onClick={isRecording ? stopRecording : startRecording}
            className={`px-8 py-3 rounded-lg shadow-lg transition-all flex items-center justify-center gap-2 ${
              isRecording
                ? 'bg-red-600 hover:bg-red-700 text-white animate-pulse'
                : 'bg-blue-600 hover:bg-blue-700 text-white'
            }`}
            disabled={isTranslating}
          >
            {isRecording ? (
              <>
                <Square size={20} />
                <span className="font-medium">
                  {language === 'es' ? 'Detener Grabación' : 'Stop Recording'}
                </span>
              </>
            ) : (
              <>
                <Mic size={20} />
                <span className="font-medium">
                  {language === 'es' ? 'Iniciar Grabación' : 'Start Recording'}
                </span>
              </>
            )}
          </button>
        </div>

        {isRecording && (
          <div className="flex justify-center mt-4">
            <div className="flex gap-1">
              {[...Array(4)].map((_, i) => (
                <div
                  key={i}
                  className="w-1 bg-red-500 animate-soundbar"
                  style={{
                    height: '32px',
                    animationDelay: `${i * 0.15}s`
                  }}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WhisperMultibrowserTranscriber;