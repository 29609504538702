import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Map as MapGL, Marker, Popup, NavigationControl, GeolocateControl } from 'react-map-gl/maplibre';
import { MapPin, Plus, AlertTriangle, Database, CheckCircle } from 'lucide-react';
import { translations } from '../translations';
import { supabase, isSupabaseConfigured, testSupabaseConnection } from '../lib/supabase';
import type { Marker as MarkerType, MarkerCategory } from '../types';
import LocationSearch from './LocationSearch';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { useIsMobile } from '../hooks/useIsMobile';
import { isWithinLast24Hours } from '../lib/dateUtils';

// US bounds
const US_BOUNDS = {
  minLng: -125.0,
  minLat: 24.396308,
  maxLng: -66.934570,
  maxLat: 49.384358
};

// Default to a closer zoom level
const DEFAULT_ZOOM = 12;

// Fallback center (will be replaced by user location)
const DEFAULT_CENTER = {
  longitude: -98.5795,
  latitude: 39.8283
};

// MapTiler API key
const MAPTILER_KEY = 'SuHEhypMCIOnIZIVbC95';

interface MapViewProps {
  language?: 'en' | 'es';
}

const MapView = ({ language = 'en' }: MapViewProps) => {
  const [viewState, setViewState] = useState({
    longitude: DEFAULT_CENTER.longitude,
    latitude: DEFAULT_CENTER.latitude,
    zoom: DEFAULT_ZOOM
  });
  const [isAddingMarker, setIsAddingMarker] = useState(false);
  const [markers, setMarkers] = useState<MarkerType[]>([]);
  const [selectedMarker, setSelectedMarker] = useState<MarkerType | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [userLocation, setUserLocation] = useState<{ lat: number; lng: number } | null>(null);
  const [feedback, setFeedback] = useState<{ message: string; type: 'success' | 'error' } | null>(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [pendingMarker, setPendingMarker] = useState<{lat: number; lng: number} | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<MarkerCategory>('ice');
  const [showCategoryDialog, setShowCategoryDialog] = useState(false);
  const [isSupabaseConnected, setIsSupabaseConnected] = useState(false);
  const mapRef = useRef<any>(null);
  const isMobile = useIsMobile();
  const fetchMarkersTimeoutRef = useRef<number | null>(null);
  const isAddingMarkerRef = useRef(false);
  const t = translations[language];

  const showFeedback = (message: string, type: 'success' | 'error' = 'success') => {
    setFeedback({ message, type });
    setTimeout(() => setFeedback(null), 3000);
  };

  const checkSupabaseConnection = async () => {
    try {
      if (!isSupabaseConfigured()) {
        setError(language === 'es' 
          ? 'Credenciales de Supabase no encontradas. Por favor, conecte a Supabase usando el botón "Connect to Supabase".'
          : 'Supabase credentials not found. Please connect to Supabase using the "Connect to Supabase" button.');
        setIsSupabaseConnected(false);
        return;
      }

      const isConnected = await testSupabaseConnection();
      setIsSupabaseConnected(isConnected);
      setError(null);
      
      if (isConnected) {
        try {
          await fetchMarkers();
        } catch (err) {
          console.error('Error fetching markers:', err);
          setError(language === 'es'
            ? 'Error al cargar los marcadores. Por favor, intente de nuevo.'
            : 'Error loading markers. Please try again.');
        }
      } else {
        setError(language === 'es' 
          ? 'Error de conexión a la base de datos. Por favor, conecte a Supabase usando el botón "Connect to Supabase".'
          : 'Database connection error. Please connect to Supabase using the "Connect to Supabase" button.');
      }
    } catch (err) {
      console.error('Supabase connection error:', err);
      setIsSupabaseConnected(false);
      setError(language === 'es' 
        ? 'Error de conexión a la base de datos. Por favor, conecte a Supabase usando el botón "Connect to Supabase".'
        : 'Database connection error. Please connect to Supabase using the "Connect to Supabase" button.');
    }
  };

  useEffect(() => {
    checkSupabaseConnection();
    
    // Get user's location immediately
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
          setViewState(prev => ({
            ...prev,
            latitude,
            longitude,
            zoom: DEFAULT_ZOOM
          }));
        },
        (error) => {
          console.warn('Geolocation error:', error);
          // Keep default center if location access fails
        }
      );
    }
  }, []);

  const fetchMarkers = async () => {
    try {
      // Prevent multiple simultaneous calls
      if (fetchMarkersTimeoutRef.current) {
        window.clearTimeout(fetchMarkersTimeoutRef.current);
        fetchMarkersTimeoutRef.current = null;
      }

      // Check connection with retry
      if (!isSupabaseConnected) {
        const isConnected = await testSupabaseConnection();
        if (!isConnected) {
          setError(language === 'es'
            ? 'Error de conexión a la base de datos. Por favor, intente de nuevo.'
            : 'Database connection error. Please try again.');
          return;
        }
        setIsSupabaseConnected(true);
      }
      
      // Only fetch active markers within viewport bounds
      const bounds = mapRef.current?.getMap().getBounds();
      const query = supabase
        .from('markers')
        .select('*');
      
      // Don't filter by active status in the database query
      // We'll handle filtering in the client side
      query.limit(100); // Limit to prevent stack depth issues
      
      if (bounds) {
        query
          .gte('latitude', Math.max(-90, bounds._sw.lat))
          .lte('latitude', Math.min(90, bounds._ne.lat))
          .gte('longitude', Math.max(-180, bounds._sw.lng))
          .lte('longitude', Math.min(180, bounds._ne.lng));
      }
      
      query.order('created_at', { ascending: false });

      // Add timeout to prevent hanging requests
      const timeoutPromise = new Promise((_, reject) => 
        setTimeout(() => reject(new Error('Request timeout')), 10000)
      );
      
      const { data, error } = await Promise.race([
        query,
        timeoutPromise
      ]) as any;

      if (error) throw error;

      if (data) {
        // Format markers and calculate active status based on timestamps
        const formattedMarkers = data.map((marker) => {
          // Determine if marker is active based on last_confirmed or created_at
          // For newly created markers, always consider them active
          const createdRecently = new Date(marker.created_at) > new Date(Date.now() - 5 * 60 * 1000); // 5 minutes
          const isActive = createdRecently || (
            marker.active !== undefined 
              ? marker.active && isWithinLast24Hours(marker.last_confirmed || marker.created_at)
              : isWithinLast24Hours(marker.last_confirmed || marker.created_at)
          );
            
          return {
          id: marker.id,
          position: { lat: marker.latitude, lng: marker.longitude },
          category: marker.category as MarkerCategory,
          createdAt: new Date(marker.created_at),
          active: isActive,
          lastConfirmed: marker.last_confirmed,
          confirmationsCount: marker.confirmations_count,
          lastStatusChange: marker.last_status_change,
          reliabilityScore: marker.reliability_score,
          negativeConfirmations: marker.negative_confirmations
          };
        });
        setMarkers(formattedMarkers);
      }
    } catch (err) {
      console.error('Error fetching markers:', err);
      const isTimeout = err instanceof Error && err.message === 'Request timeout';
      
      if (!isSupabaseConnected) {
        setError(language === 'es' 
          ? 'Por favor, conecte a Supabase usando el botón "Connect to Supabase".'
          : 'Please connect to Supabase using the "Connect to Supabase" button.');
      } else {
        showFeedback(
          isTimeout
            ? (language === 'es' ? 'La solicitud tardó demasiado. Intente de nuevo.' : 'Request timed out. Please try again.')
            : (t.errors?.fetchMarkers || 'Error loading markers'),
          'error'
        );
        
        // Reset connection state on error to trigger reconnection
        setIsSupabaseConnected(false);
      }
    }
  };

  useEffect(() => {
    if (isSupabaseConnected) {
      fetchMarkers();
    }
  }, [isSupabaseConnected]);

  const handleMapClick = useCallback((event: any) => {
    if (!isAddingMarker || !mapLoaded) return;

    // Get click coordinates
    const { lng, lat } = event.lngLat;
    
    // Validate coordinates are within US bounds
    if (lng < US_BOUNDS.minLng || lng > US_BOUNDS.maxLng ||
        lat < US_BOUNDS.minLat || lat > US_BOUNDS.maxLat) {
      return;
    }

    // Set pending marker and show category dialog
    setPendingMarker({ lat, lng });
    setShowCategoryDialog(true);
    setIsAddingMarker(false);
  }, [isAddingMarker, mapLoaded]);

  const handleConfirm = async (markerId: string, isActive: boolean) => {
    try {
      if (!userLocation) {
        showFeedback(
          language === 'es' 
            ? 'Se requiere tu ubicación para confirmar marcadores'
            : 'Your location is required to confirm markers',
          'error'
        );
        return;
      }

      const { error } = await supabase.rpc('handle_marker_confirmation', {
        in_marker_id: markerId,
        in_is_present: isActive,
        in_user_ip: 'anonymous',
        in_user_lat: userLocation.lat,
        in_user_lng: userLocation.lng
      });

      if (error) {
        if (error.message.includes('too far')) {
          showFeedback(
            language === 'es'
              ? 'Estás demasiado lejos de este marcador'
              : error.message,
            'error'
          );
        } else {
          throw error;
        }
        return;
      }

      showFeedback(
        language === 'es' 
          ? 'Gracias por tu confirmación' 
          : 'Thank you for your confirmation'
      );
      
      setSelectedMarker(null);
      // Add a small delay before fetching to allow the database trigger to complete
      setTimeout(fetchMarkers, 500);
      await fetchMarkers();
    } catch (err) {
      console.error('Error confirming marker:', err);
      showFeedback(t.errors?.general || 'Error confirming marker', 'error');
    }
  };

  const handleCategorySelect = async () => {
    if (!pendingMarker) return;

    console.log('Adding marker:', {
      position: pendingMarker,
      category: selectedCategory
    });

    // Prevent multiple submissions
    if (isAddingMarkerRef.current) return;
    isAddingMarkerRef.current = true;

    try {
      // Add marker to database
      const { data, error } = await supabase
        .from('markers')
        .insert({
          latitude: pendingMarker.lat,
          longitude: pendingMarker.lng,
          category: selectedCategory,
          active: true,
         last_confirmed: new Date().toISOString(),
          title: `${selectedCategory.toUpperCase()} Sighting`,
          description: `${selectedCategory.toUpperCase()} activity reported in this area`
        }, { count: 'exact' })
        .select()
        .single();

      console.log('Supabase insert response:', { data, error });

      if (error) throw error;

      if (data) {
        const newMarker: MarkerType = {
          id: data.id,
          position: { lat: data.latitude, lng: data.longitude },
          category: data.category as MarkerCategory,
          createdAt: new Date(data.created_at),
          active: data.active,
         lastConfirmed: new Date(data.last_confirmed),
          reliabilityScore: 1.0,
          negativeConfirmations: 0
        };

        setMarkers(prev => [...prev, newMarker]);
        console.log('Added marker to local state:', newMarker);
        console.log('Current markers state:', [...markers, newMarker]);
        showFeedback(
          language === 'es'
            ? 'Marcador agregado exitosamente'
            : 'Marker added successfully'
        );
      }
      
      // Fetch markers after a short delay to prevent stack issues
      console.log('Scheduling fetchMarkers after delay');
      setTimeout(() => {
        console.log('Executing delayed fetchMarkers');
        fetchMarkers();
      }, 500);
    } catch (err) {
      console.error('Error adding marker:', err);
      console.log('Error details:', err);
      showFeedback(t.errors?.general || 'Error adding marker', 'error');
    } finally {
      setPendingMarker(null);
      setShowCategoryDialog(false);
      isAddingMarkerRef.current = false;
    }
  };

  const getMarkerColor = (marker: MarkerType) => {
    // Check if marker is inactive (over 24h old)
    const isInactive = !isWithinLast24Hours(marker.lastConfirmed || marker.createdAt);
    if (isInactive || !marker.active) return '#6B7280'; // gray-500 for inactive markers
    return marker.category === 'police' ? '#ef4444' : '#3b82f6';
  };

  const getMarkerOpacity = (marker: MarkerType) => {
    // Check if marker is inactive (over 24h old)
    const isInactive = !isWithinLast24Hours(marker.lastConfirmed || marker.createdAt);
    return (isInactive || !marker.active) ? 0.4 : 1;
  };

  // Show all markers - we'll handle visibility through opacity
  const filteredMarkers = markers;
  
  // Log filtered marker count
  useEffect(() => {
    console.log(`Showing ${filteredMarkers.length} of ${markers.length} total markers`);
  }, [filteredMarkers.length, markers.length]);

  const handleLocationSelect = (lat: number, lng: number) => {
    setViewState({
      ...viewState,
      latitude: lat,
      longitude: lng,
      zoom: 16
    });
  };
  
  const markerSize = 30;

  return (
    <div className="h-screen w-screen relative">
      {!isSupabaseConnected && (
        <div className="absolute inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-[1002] px-4">
          <div className="bg-[#1a1f2e] p-8 rounded-2xl shadow-2xl w-full max-w-md mx-auto border border-gray-700/50">
            <div className="flex items-center justify-center mb-6 text-blue-500">
              <Database size={48} />
            </div>
            <h3 className="text-2xl font-bold text-white mb-4 text-center">
              {language === 'es' ? 'Conexión Requerida' : 'Connection Required'}
            </h3>
            <p className="text-gray-300 text-center mb-6">
              {language === 'es'
                ? 'Por favor, conecte a Supabase usando el botón "Connect to Supabase" en la esquina superior derecha.'
                : 'Please connect to Supabase using the "Connect to Supabase" button in the top right corner.'}
            </p>
            <div className="flex justify-center">
              <button
                onClick={checkSupabaseConnection}
                className="px-6 py-3 bg-blue-600 text-white rounded-xl hover:bg-blue-700 transition-colors text-lg font-medium"
              >
                {language === 'es' ? 'Reintentar Conexión' : 'Retry Connection'}
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="fixed bottom-24 right-4 z-[1001] space-y-2 w-72">
        <button
          className={`w-full px-4 py-2 rounded-lg shadow-md flex items-center justify-center ${
            isAddingMarker
              ? 'bg-green-600/90 backdrop-blur-sm text-white hover:bg-green-700'
              : 'bg-gray-800/90 backdrop-blur-sm text-gray-100 hover:bg-gray-700'
          }`}
          onClick={() => setIsAddingMarker(!isAddingMarker)}
        >
          <Plus className="mr-2" size={20} />
          {isAddingMarker ? t.clickToPlace : 'Add Mark'}
        </button>

        <div className="relative">
          <LocationSearch
            onLocationSelect={handleLocationSelect}
            language={language}
            className="bg-black/80 backdrop-blur-md shadow-lg border border-gray-700/50"
          />
        </div>

        {/* Map Legend */}
        <div className="bg-black/80 backdrop-blur-md rounded-lg shadow-lg border border-gray-700/50">
          <div className="p-4">
            <h3 className="text-sm font-medium text-gray-300 mb-3">
              {language === 'es' ? 'Leyenda del Mapa' : 'Map Legend'}
            </h3>
            <div className="space-y-2">
              <div className="flex items-center gap-2">
                <MapPin size={16} className="text-blue-500" />
                <span className="text-sm text-gray-300">ICE - {language === 'es' ? 'Activo' : 'Active'}</span>
              </div>
              <div className="flex items-center gap-2">
                <MapPin size={16} className="text-red-500" />
                <span className="text-sm text-gray-300">{language === 'es' ? 'Policía - Activo' : 'Police - Active'}</span>
              </div>
              <div className="flex items-center gap-2">
                <MapPin size={16} className="text-gray-500 opacity-40" />
                <span className="text-sm text-gray-400">
                  {language === 'es' ? 'Inactivo (>24h)' : 'Inactive (>24h)'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {feedback && (
        <div className="fixed bottom-20 left-1/2 transform -translate-x-1/2 z-[1001] animate-fade-in">
          <div className={`${
            feedback.type === 'error' ? 'bg-red-900/90' : 'bg-green-900/90'
          } backdrop-blur-sm text-white px-6 py-3 rounded-lg shadow-lg flex items-center`}>
            {feedback.type === 'error' ? (
              <AlertTriangle className="mr-2 flex-shrink-0" size={20} />
            ) : (
              <CheckCircle className="mr-2 flex-shrink-0" size={20} />
            )}
            <span className="text-sm font-medium">{feedback.message}</span>
          </div>
        </div>
      )}

      {showCategoryDialog && pendingMarker && (
        <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-[1002] px-4">
          <div className="bg-[#1a1f2e] p-8 rounded-2xl shadow-2xl w-full max-w-md mx-auto border border-gray-700/50">
            <h3 className="text-2xl font-bold text-white mb-6">
              {language === 'es' ? 'Seleccionar Categoría' : 'Select Category'}
            </h3>
            <div className="space-y-6">
              <div className="grid grid-cols-2 gap-4">
                <button
                  onClick={() => setSelectedCategory('ice')}
                  className={`w-full px-6 py-4 rounded-xl text-lg font-medium transition-all ${
                    selectedCategory === 'ice'
                      ? 'bg-blue-600 text-white ring-2 ring-blue-400 ring-offset-2 ring-offset-[#1a1f2e]'
                      : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                  }`}
                >
                  ICE
                </button>
                <button
                  onClick={() => setSelectedCategory('police')}
                  className={`w-full px-6 py-4 rounded-xl text-lg font-medium transition-all ${
                    selectedCategory === 'police'
                      ? 'bg-red-600 text-white ring-2 ring-red-400 ring-offset-2 ring-offset-[#1a1f2e]'
                      : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                  }`}
                >
                  {language === 'es' ? 'Policía' : 'Police'}
                </button>
              </div>
              <div className="grid grid-cols-2 gap-4 pt-2">
                <button
                  onClick={() => {
                    setPendingMarker(null);
                    setShowCategoryDialog(false);
                  }}
                  className="w-full px-6 py-3 bg-gray-800 text-gray-300 rounded-xl hover:bg-gray-700 transition-colors text-lg font-medium"
                >
                  {language === 'es' ? 'Cancelar' : 'Cancel'}
                </button>
                <button
                  onClick={handleCategorySelect}
                  className="w-full px-6 py-3 bg-green-600 text-white rounded-xl hover:bg-green-700 transition-colors text-lg font-medium"
                >
                  {language === 'es' ? 'Guardar' : 'Save'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <MapGL
        {...viewState}
        onMove={evt => setViewState(evt.viewState)}
        style={{ width: '100%', height: '100%' }}
        mapStyle={`https://api.maptiler.com/maps/streets/style.json?key=${MAPTILER_KEY}`}
        onClick={handleMapClick}
        onMoveEnd={() => {
          // Fetch new markers when map view changes
          if (mapLoaded) {
            fetchMarkers();
          }
        }}
        maxBounds={[
          [US_BOUNDS.minLng, US_BOUNDS.minLat],
          [US_BOUNDS.maxLng, US_BOUNDS.maxLat]
        ]}
        minZoom={8}
        maxZoom={19}
        mapLib={maplibregl}
        onLoad={() => {
          setMapLoaded(true);
          fetchMarkers();
        }}
        ref={mapRef}
        renderWorldCopies={false}
        preserveDrawingBuffer={true}
        attributionControl={false}
      >
        {mapLoaded && (
          <>
            <GeolocateControl
              position="top-left"
              trackUserLocation
              showUserLocation
              showAccuracyCircle
              fitBoundsOptions={{ maxZoom: 16 }}
              positionOptions={{
                enableHighAccuracy: true,
                timeout: 6000,
                maximumAge: 0
              }}
              onGeolocate={(e) => {
                setUserLocation({
                  lat: e.coords.latitude,
                  lng: e.coords.longitude
                });
              }}
            />
            <NavigationControl position="top-left" />
          </>
        )}

        {filteredMarkers.map((marker) => (
          <Marker
            key={marker.id}
            longitude={marker.position.lng}
            latitude={marker.position.lat}
            onClick={(e) => {
              e.originalEvent.stopPropagation();
              setSelectedMarker(marker);
            }}
          >
            <div className="cursor-pointer">
              <MapPin
                size={markerSize}
                color={getMarkerColor(marker)}
                style={{ opacity: getMarkerOpacity(marker) }}
                className="transform -translate-x-1/2 -translate-y-1/2 transition-colors"
              />
            </div>
          </Marker>
        ))}

        {selectedMarker && (
          <Popup
            longitude={selectedMarker.position.lng}
            latitude={selectedMarker.position.lat}
            onClose={() => setSelectedMarker(null)}
            closeButton={true}
            closeOnClick={false}
            anchor="bottom"
            className="marker-popup"
          >
            <div className="p-6 w-full bg-[#1a1f2e] text-gray-100 rounded-xl border border-gray-700/50">
              <div className="font-bold text-2xl mb-4 flex items-center justify-between">
                <span>{t.categories[selectedMarker.category]}</span>
                {!selectedMarker.active && (
                  <span className="text-sm font-normal text-gray-400">Archived</span>
                )}
                {selectedMarker.reliabilityScore && selectedMarker.reliabilityScore < 0.5 && (
                  <AlertTriangle className="text-yellow-500" size={20} title="Low reliability" />
                )}
              </div>
              
              <div className="space-y-3 text-sm">
                <div className="text-gray-300">
                  {t.lastConfirmed}: {selectedMarker.lastConfirmed ? 
                    new Date(selectedMarker.lastConfirmed).toLocaleString(language === 'es' ? 'es-ES' : 'en-US') : 
                    'N/A'
                  }
                </div>
                <div className="text-gray-300">
                  {t.confirmations}: {selectedMarker.confirmationsCount || 0}
                </div>
                {selectedMarker.reliabilityScore && (
                  <div className="mt-4">
                    <div className="text-xs text-gray-400 mb-1">Reliability</div>
                    <div className="w-full bg-gray-700/50 rounded-full h-2">
                      <div
                        className="bg-blue-600 rounded-full h-2 transition-all duration-300"
                        style={{ width: `${selectedMarker.reliabilityScore * 100}%` }}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="mt-6 grid grid-cols-2 gap-3">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleConfirm(selectedMarker.id, true);
                  }}
                  className="w-full px-4 py-2 bg-green-600 text-white rounded-lg text-sm font-medium hover:bg-green-700 transition-colors"
                >
                  {t.stillPresent}
                </button>
                <button
                  onClick={async (e) => {
                    e.stopPropagation();
                    try {
                      // Set last_confirmed to 27 hours ago and ensure active is false
                      const pastDate = new Date(Date.now() - 27 * 60 * 60 * 1000);
                      const { error } = await supabase
                        .from('markers')
                        .update({ 
                          last_confirmed: pastDate.toISOString(),
                          active: false // Force inactive state for old markers
                        })
                        .eq('id', selectedMarker.id);
                      
                      if (error) throw error;
                      
                      showFeedback('Marker aged by 27 hours');
                      setSelectedMarker(null);
                      // Add a small delay before fetching to allow the database trigger to complete
                      setTimeout(fetchMarkers, 500);
                      await fetchMarkers();
                    } catch (err) {
                      console.error('Error aging marker:', err);
                      showFeedback('Failed to age marker', 'error');
                    }
                  }}
                  className="w-full px-4 py-2 bg-yellow-600 text-white rounded-lg text-sm font-medium hover:bg-yellow-700 transition-colors"
                >
                  Test 27h Age
                </button>
              </div>
            </div>
          </Popup>
        )}
      </MapGL>
    </div>
  );
};

export default MapView;