// CRITICAL: These statements must remain in English as they are sent directly to ElevenLabs
export const redCardStatements = [
  {
    title: {
      en: "Recording Notification",
      es: "Notificación de Grabación"
    },
    text: "This conversation is being recorded for my documentation should I need it. I choose to exercise my 5th Amendment rights under the United States Constitution",
    translation: "Esta conversación está siendo grabada para mi documentación en caso de que la necesite. Elijo ejercer mis derechos de la Quinta Enmienda bajo la Constitución de los Estados Unidos",
    audioFile: "recording-notification.mp3"
  },
  {
    title: {
      en: "Constitutional Rights",
      es: "Derechos Constitucionales"
    },
    text: "I do not wish to speak with you, answer your questions, or sign or hand you any documents based on my 5th Amendment rights under the United States Constitution.",
    translation: "No deseo hablar con usted, responder sus preguntas, ni firmar o entregarle documentos basándome en mis derechos de la Quinta Enmienda bajo la Constitución de los Estados Unidos.",
    audioFile: "constitutional-rights.mp3"
  },
  {
    title: {
      en: "No Permission to Search",
      es: "Sin Permiso para Registrar"
    },
    text: "I do not give you permission to search any of my belongings based on my 4th Amendment rights.",
    translation: "No le doy permiso para registrar ninguna de mis pertenencias basándome en mis derechos de la Cuarta Enmienda.",
    audioFile: "no-search-permission.mp3"
  },
  {
    title: {
      en: "Request Badge Numbers",
      es: "Solicitar Números de Placa"
    },
    text: "I would request badge numbers from all officers present.",
    translation: "Solicito los números de placa de todos los oficiales presentes.",
    audioFile: "badge-numbers.mp3"
  },
  {
    title: {
      en: "Free to Go?",
      es: "¿Libre para Irme?"
    },
    text: "Am I free to go? Yes or No.",
    translation: "¿Soy libre de irme? Sí o No.",
    audioFile: "free-to-go.mp3"
  },
  {
    title: {
      en: "Thank you. Goodbye.",
      es: "Gracias. Adiós."
    },
    text: "Thank you. I have documented this for my evidence. Have a good day Officer.",
    translation: "Gracias. He documentado esto como evidencia. Que tenga un buen día, Oficial.",
    audioFile: "goodbye.mp3"
  }
];

export const myhomeStatements = [
  {
    title: {
      en: "Fifth Amendment Rights",
      es: "Derechos de la Quinta Enmienda"
    },
    text: "I do not wish to speak with you, answer your questions, or sign or hand you any documents, based on my Fifth Amendment rights under the United States Constitution.",
    translation: "No deseo hablar con usted, responder sus preguntas, ni firmar o entregarle documentos, basándome en mis derechos de la Quinta Enmienda bajo la Constitución de los Estados Unidos.",
    audioFile: "myhome-fifth-amendment.mp3"
  },
  {
    title: {
      en: "Fourth Amendment Rights",
      es: "Derechos de la Cuarta Enmienda"
    },
    text: "I do not give you permission to enter my home, based on my Fourth Amendment rights under the United States Constitution, unless you have a warrant to enter, signed by a judge or magistrate, with my name on it that you slide under the door.",
    translation: "No le doy permiso para entrar a mi casa, basándome en mis derechos de la Cuarta Enmienda bajo la Constitución de los Estados Unidos, a menos que tenga una orden judicial para entrar, firmada por un juez o magistrado, con mi nombre, que deslice por debajo de la puerta.",
    audioFile: "myhome-fourth-amendment.mp3"
  },
  {
    title: {
      en: "Warrant Request",
      es: "Solicitud de Orden Judicial"
    },
    text: "Please slide the warrant to enter - signed by a judge or magistrate with my name - under the door. If you do not have one, I do not wish to speak with you, answer your questions, or sign or hand you any documents, based on my Fifth Amendment rights under the United States Constitution.",
    translation: "Por favor, deslice la orden judicial para entrar - firmada por un juez o magistrado con mi nombre - por debajo de la puerta. Si no tiene una, no deseo hablar con usted, responder sus preguntas, ni firmar o entregarle documentos, basándome en mis derechos de la Quinta Enmienda bajo la Constitución de los Estados Unidos.",
    audioFile: "myhome-warrant-request.mp3"
  },
  {
    title: {
      en: "Search Permission",
      es: "Permiso de Registro"
    },
    text: "I do not give you permission to search any of my belongings based on my 4th amendment rights.",
    translation: "No le doy permiso para registrar ninguna de mis pertenencias basándome en mis derechos de la Cuarta Enmienda.",
    audioFile: "myhome-search-permission.mp3"
  },
  {
    title: {
      en: "Identify Authority",
      es: "Identificar Autoridad"
    },
    text: "Can you please identify yourself. Are you with local law enforcement or with Immigration and Customs Enforcement.",
    translation: "¿Puede identificarse por favor? ¿Es usted de la policía local o de Inmigración y Control de Aduanas?",
    audioFile: "myhome-identify-authority.mp3"
  },
  {
    title: {
      en: "Badge Numbers",
      es: "Números de Placa"
    },
    text: "I would request badge numbers from all officers present.",
    translation: "Solicito los números de placa de todos los oficiales presentes.",
    audioFile: "myhome-badge-numbers.mp3"
  }
];

export const proofStatements = [
  {
    title: {
      en: "Notify Recording",
      es: "Notificar Grabación"
    },
    text: "This conversation is being recorded for documentary evidence should I need it."
  },
  {
    title: {
      en: "Constitutional Rights",
      es: "Derechos Constitucionales"
    },
    text: "Under my rights in the U.S. Constitution- the fourth, fifth, and fourteenth - I do not have to answer any of your questions unless you have a signed warrant from a judge that you can present me. Do you have one - yes or no?"
  },
  {
    title: {
      en: "Badge Numbers",
      es: "Números de Placa"
    },
    text: "I would request badge numbers from all officers present."
  },
  {
    title: {
      en: "Free to Go",
      es: "Libre de Irme"
    },
    text: "Am I free to go? Yes or no."
  },
  {
    title: {
      en: "Goodbye",
      es: "Despedida"
    },
    text: "Thank you. I have documented this for my records. Have a good day officer."
  }
];