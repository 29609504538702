import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { 
  Save, 
  Trash2, 
  GripVertical,
  Plus, 
  AlertTriangle,
  Bold,
  Italic,
  Link,
  List,
  ListOrdered,
  LogOut,
  Cloud,
  X,
  Check,
  ChevronDown,
  ChevronUp,
  Youtube,
  Video
} from 'lucide-react';

interface RightsSection {
  id: string;
  title: {
    en: string;
    es: string;
  };
  content: {
    en: string[];
    es: string[];
  };
  order: number;
  is_case_law?: boolean;
}

const RightsEditor = () => {
  const [sections, setSections] = useState<RightsSection[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [saving, setSaving] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkAuth();
    fetchRightsSections();
  }, []);

  const checkAuth = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      setIsAuthenticated(!!session);
      
      if (!session) {
        navigate('/login', { state: { from: '/rights-editor' } });
      }
    } catch (error) {
      console.error('Auth check error:', error);
      navigate('/login', { state: { from: '/rights-editor' } });
    } finally {
      setLoading(false);
    }
  };

  const fetchRightsSections = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('rights_sections')
        .select('*')
        .order('order', { ascending: true });

      if (error) throw error;
      
      if (data && data.length > 0) {
        setSections(data);
      } else {
        // If no data exists, create default sections
        const defaultSections = getDefaultSections();
        setSections(defaultSections);
      }
    } catch (err) {
      console.error('Error fetching rights sections:', err);
      setError('Failed to load rights sections');
      // Fall back to default sections
      setSections(getDefaultSections());
    } finally {
      setLoading(false);
    }
  };

  const getDefaultSections = (): RightsSection[] => {
    return [
      {
        id: crypto.randomUUID(),
        title: {
          en: 'Tia Maria - AI Assistant',
          es: 'Tía María - Asistente de IA'
        },
        content: {
          en: [
            'You can get many of your questions answered by talking with this AI Chatbot. This chatbot is trained on information from <a href="https://lulac.org/" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">LULAC</a> and the <a href="https://www.aclu.org/" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">ACLU</a>. This ensures that the information you receive is the most up to date and correct.',
            '<elevenlabs-convai agent-id="nPjA5PlVWxRd7L1Ypou4"></elevenlabs-convai>'
          ],
          es: [
            'Puedes obtener respuestas a muchas de tus preguntas hablando con este chatbot de IA. Este chatbot está entrenado con información de <a href="https://lulac.org/" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">LULAC</a> y la <a href="https://www.aclu.org/" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">ACLU</a>. Esto garantiza que la información que recibas sea la más actualizada y precisa.',
            '<elevenlabs-convai agent-id="nPjA5PlVWxRd7L1Ypou4"></elevenlabs-convai>'
          ]
        },
        order: 0
      },
      {
        id: crypto.randomUUID(),
        title: {
          en: 'The Consular App',
          es: 'La Aplicación Consular'
        },
        content: {
          en: [
            'The government of Mexico has launched the mobile application ConsulApp Contigo, designed to provide support to Mexicans residing in the United States, especially those in vulnerable situations or facing possible immigration detentions. This tool allows users to quickly contact Mexican consulates and their family members in case of an emergency.',
            '',
            'Main Features of ConsulApp Contigo:',
            '• Emergency Button: By pressing it for five seconds, users can send an immediate alert to the nearest Mexican consulate and their emergency contacts, notifying them of a possible detention or critical situation.',
            '',
            '• Know Your Rights Information: The app offers a section titled "Know Your Rights", where it details the actions individuals can take during encounters with immigration agents, ensuring they are informed about their legal protections.',
            '',
            '• Consulate Locator: Includes an interactive map showing the locations of the 53 Mexican consular offices in the United States, making it easier for users to find and contact the nearest consulate.',
            '',
            '• Appointment Scheduling: Users can schedule appointments for consular services, such as obtaining passports or other official documents, directly through the app.',
            '',
            '<a href="https://apps.apple.com/us/app/consulapp-contigo/id6740634286" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">Download ConsulApp Contigo for iOS</a>',
            '',
            '<a href="https://play.google.com/store/apps/details?id=com.sre.consulapp&hl=en_US&pli=1" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">Download ConsulApp Contigo for Android</a>',
            '',
            '<div class="responsive-video-container"><iframe width="100%" height="315" src="https://www.youtube.com/embed/Atjpr1hSI08?si=8b5PVI2ktVMrhYL4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>'
          ],
          es: [
            'El gobierno de México ha lanzado la aplicación móvil ConsulApp Contigo, diseñada para brindar apoyo a los mexicanos que residen en Estados Unidos, especialmente aquellos en situaciones vulnerables o que enfrentan posibles detenciones migratorias. Esta herramienta permite a los usuarios contactar rápidamente a los consulados mexicanos y a sus familiares en caso de emergencia.',
            '',
            'Características principales de ConsulApp Contigo:',
            '',
            '• Botón de emergencia: Al presionarlo durante cinco segundos, el usuario puede enviar una alerta inmediata al consulado mexicano más cercano y a sus contactos de emergencia, notificando una posible detención o situación crítica.',
            '',
            '• Información sobre derechos: La aplicación ofrece una sección titulada "Conoce tus derechos", donde se detallan las acciones que las personas pueden tomar durante encuentros con agentes de inmigración, asegurando que estén informadas sobre sus garantías legales.',
            '',
            '• Localización de consulados: Incluye un mapa interactivo que muestra las ubicaciones de las 53 oficinas consulares mexicanas en Estados Unidos, facilitando a los usuarios encontrar y contactar el consulado más cercano.',
            '',
            '• Programación de citas: Los usuarios pueden agendar citas para servicios consulares, como la obtención de pasaportes u otros documentos oficiales, directamente desde la aplicación.',
            '',
            '<a href="https://apps.apple.com/us/app/consulapp-contigo/id6740634286" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">Pueden Obtener el App Aqui para iOS</a>',
            '',
            '<a href="https://play.google.com/store/apps/details?id=com.sre.consulapp&hl=en_US&pli=1" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">Pueden Obtener el App Aqui para Android</a>',
            '',
            '<div class="responsive-video-container"><iframe width="100%" height="315" src="https://www.youtube.com/embed/nM-dEiKZ0sk?si=9MoC0WHISKRYaW5W" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>'
          ]
        },
        order: 1
      },
      {
        id: crypto.randomUUID(),
        title: {
          en: 'The U.S. Constitution Protects Everyone',
          es: 'La Constitución de EE.UU. Protege a Todos'
        },
        content: {
          en: [
            'Its important to know that everyone in the United States is Protected by the U.S. Constitution— Citizen or not. The U.S. Constitution protects everyone that is physically in the U.S and is not meant for only citizens of the U.S. These rights ensure fair treatment for all.',
            '',
            'The U.S. Constitution has 27 amendments. The first ten amendments, known as the Bill of Rights, were ratified in 1791, and the most recent amendment, the 27th Amendment, was ratified in 1992. There are several of these amendments that are specific to protecting your rights:',
            '',
            'Fifth Amendment:',
            'Right to stay quiet if talking might get you in trouble.',
            'Everyone gets fair treatment by the law.',
            '',
            '**Fourth Amendment:**',
            '• Police need a warrant, a judge\'s permission, to search you or your things.',
            '• A warrant is different from an ICE order: A warrant comes from a judge, while an ICE order does not.',
            '• Protects your privacy, including immigrants.',
            '',
            '**Fourteenth Amendment:**',
            'Everyone deserves fair legal processes.',
            'Laws must treat everyone equally, giving the same rights to all.'
          ],
          es: [
            'Es importante saber que todas las personas en los Estados Unidos están protegidas por la Constitución de EE.UU., sin importar si son ciudadanos o no. La Constitución de EE.UU. protege a cualquier persona que se encuentre físicamente en el país y no está destinada únicamente a los ciudadanos. Estos derechos garantizan un trato justo para todos.',
            '',
            'La Constitución de los Estados Unidos tiene 27 enmiendas. Las primeras diez enmiendas, conocidas como la Carta de Derechos, fueron ratificadas en 1791, y la enmienda más reciente, la 27ª Enmienda, fue ratificada en 1992. Varias de estas enmiendas están diseñadas específicamente para proteger tus derechos:',
            '',
            'Cuarta Enmienda:',
            '• La policía necesita una orden judicial, el permiso de un juez, para registrarte a ti o tus pertenencias.',
            '• Una orden judicial es diferente de una orden de ICE: una orden judicial proviene de un juez, mientras que una orden de ICE no.',
            '• Protege tu privacidad, incluyendo a los inmigrantes.',
            '',
            'Quinta Enmienda:',
            '• Derecho a permanecer en silencio si hablar podría meterte en problemas.',
            '• Todas las personas tienen derecho a un trato justo bajo la ley.',
            '',
            'Decimocuarta Enmienda:',
            '• Todos merecen un proceso legal justo.',
            '• Las leyes deben tratar a todas las personas por igual, otorgando los mismos derechos a todos.'
          ]
        },
        order: 2
      },
      {
        id: crypto.randomUUID(),
        title: {
          en: 'What Happens If ICE Comes to My Home',
          es: 'Qué Hacer Si ICE Viene a Mi Casa'
        },
        content: {
          en: [
            '• You have the right to remain silent and do not have to discuss your immigration status',
            '• Ask if they have a warrant signed by a judge. If they don\'t, you can refuse to let them in',
            '• Stay calm and keep the door closed',
            '• If they have a valid warrant, you may have to let them in, but you still have the right to remain silent and consult a lawyer',
            '• Do not sign any documents without speaking to an attorney'
          ],
          es: [
            '• Tienes derecho a guardar silencio y no tienes que discutir tu estatus migratorio',
            '• Pregunta si tienen una orden judicial firmada por un juez. Si no la tienen, puedes negarte a dejarlos entrar',
            '• Mantén la calma y mantén la puerta cerrada',
            '• Si tienen una orden válida, puede que tengas que dejarlos entrar, pero aún tienes derecho a guardar silencio y consultar a un abogado',
            '• No firmes ningún documento sin hablar con un abogado'
          ]
        },
        order: 3
      },
      {
        id: crypto.randomUUID(),
        title: {
          en: 'What Happens If ICE Approaches Me on the Street',
          es: 'Qué Hacer Si ICE Me Aborda en la Calle'
        },
        content: {
          en: [
            '• You have the right to remain silent and do not have to discuss your immigration status',
            '• Ask if you are free to leave. If they say yes, calmly walk away',
            '• You have the right to refuse to show identification or answer questions',
            '• Remember, you do not have to sign any documents without speaking to an attorney'
          ],
          es: [
            '• Tienes derecho a guardar silencio y no tienes que discutir tu estatus migratorio',
            '• Pregunta si eres libre de irte. Si dicen que sí, aléjate calmadamente',
            '• Tienes derecho a negarte a mostrar identificación o responder preguntas',
            '• Recuerda, no tienes que firmar ningún documento sin hablar con un abogado'
          ]
        },
        order: 4
      },
      {
        id: crypto.randomUUID(),
        title: {
          en: 'Important Legal Cases',
          es: 'Casos Legales Importantes'
        },
        content: {
          en: [
            'Yick Wo v. Hopkins (1886)',
            'Established that the Equal Protection Clause of the 14th Amendment applies to all persons, not just citizens, reinforcing constitutional protections for immigrants.',
            '<a href="https://en.wikipedia.org/wiki/Yick_Wo_v._Hopkins" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">Learn more</a>',
            '',
            'Zadvydas v. Davis (2001)',
            'Limited the government\'s power to indefinitely detain immigrants who have been ordered removed but cannot be deported.',
            '<a href="https://en.wikipedia.org/wiki/Zadvydas_v._Davis" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">Learn more</a>',
            '',
            'Plyler v. Doe (1982)',
            'Established that states cannot deny public education to undocumented immigrant children.',
            '<a href="https://en.wikipedia.org/wiki/Plyler_v._Doe" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">Learn more</a>'
          ],
          es: [
            'Yick Wo v. Hopkins (1886)',
            'Estableció que la Cláusula de Protección Igualitaria de la 14ª Enmienda se aplica a todas las personas, no solo a los ciudadanos, reforzando las protecciones constitucionales para inmigrantes.',
            '<a href="https://en.wikipedia.org/wiki/Yick_Wo_v._Hopkins" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">Más información</a>',
            '',
            'Zadvydas v. Davis (2001)',
            'Limitó el poder del gobierno para detener indefinidamente a inmigrantes que han recibido orden de deportación pero no pueden ser deportados.',
            '<a href="https://en.wikipedia.org/wiki/Zadvydas_v._Davis" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">Más información</a>',
            '',
            'Plyler v. Doe (1982)',
            'Estableció que los estados no pueden negar educación pública a niños inmigrantes indocumentados.',
            '<a href="https://en.wikipedia.org/wiki/Plyler_v._Doe" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">Más información</a>'
          ]
        },
        order: 5,
        is_case_law: true
      }
    ];
  };

  const updateSection = (index: number, field: string, language: 'en' | 'es', value: any) => {
    const newSections = [...sections];
    
    if (field === 'title') {
      newSections[index].title[language] = value;
    } else if (field === 'content') {
      // For content, we're handling arrays of strings
      newSections[index].content[language] = Array.isArray(value) 
        ? value 
        : value.split('\n');
    } else if (field === 'is_case_law') {
      newSections[index].is_case_law = value;
    }
    
    setSections(newSections);
  };

  const moveSection = (index: number, direction: 'up' | 'down') => {
    if ((direction === 'up' && index === 0) || 
        (direction === 'down' && index === sections.length - 1)) {
      return;
    }

    const newSections = [...sections];
    const targetIndex = direction === 'up' ? index - 1 : index + 1;
    
    // Swap the sections
    [newSections[index], newSections[targetIndex]] = [newSections[targetIndex], newSections[index]];
    
    // Update order values
    newSections.forEach((section, idx) => {
      section.order = idx;
    });
    
    setSections(newSections);
  };

  const addSection = () => {
    const newSection: RightsSection = {
      id: crypto.randomUUID(),
      title: {
        en: 'New Section',
        es: 'Nueva Sección'
      },
      content: {
        en: ['Enter content here'],
        es: ['Ingrese contenido aquí']
      },
      order: sections.length,
      is_case_law: false
    };
    
    setSections([...sections, newSection]);
  };

  const deleteSection = (index: number) => {
    const newSections = [...sections];
    newSections.splice(index, 1);
    
    // Update order values
    newSections.forEach((section, idx) => {
      section.order = idx;
    });
    
    setSections(newSections);
  };

  const insertYoutubeEmbed = (index: number, language: 'en' | 'es') => {
    const youtubeUrl = prompt('Enter YouTube video URL:');
    if (!youtubeUrl) return;
    
    try {
      // Extract video ID from various YouTube URL formats
      const videoId = youtubeUrl.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/)?.[1];
      
      if (!videoId) {
        alert('Invalid YouTube URL. Please enter a valid YouTube video URL.');
        return;
      }
      
      const embedCode = `<div class="responsive-video-container"><iframe width="100%" height="315" src="https://www.youtube.com/embed/${videoId}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>`;
      
      const newContent = [...sections[index].content[language], '', embedCode];
      updateSection(index, 'content', language, newContent);
    } catch (err) {
      alert('Error processing YouTube URL. Please try again.');
    }
  };

  const insertLink = (index: number, language: 'en' | 'es') => {
    const url = prompt('Enter URL:');
    if (!url) return;
    
    const text = prompt('Enter link text:');
    if (!text) return;
    
    try {
      const linkHtml = `<a href="${url}" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">${text}</a>`;
      
      const textarea = document.getElementById(`content-${language}-${index}`) as HTMLTextAreaElement;
      if (textarea) {
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const currentValue = textarea.value;
        const newValue = 
          currentValue.slice(0, start) + 
          linkHtml + 
          currentValue.slice(end);
        
        textarea.value = newValue;
        updateSection(index, 'content', language, newValue);
        textarea.focus();
        textarea.selectionStart = start + linkHtml.length;
        textarea.selectionEnd = textarea.selectionStart;
      }
    } catch (err) {
      alert('Error creating link. Please try again.');
    }
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      setError(null);
      
      // First, delete all existing sections
      const { error: deleteError } = await supabase
        .from('rights_sections')
        .delete()
        .not('id', 'is', null);
      
      if (deleteError) {
        console.error('Error deleting existing sections:', deleteError);
        throw new Error('Failed to update rights sections. Please try again.');
      }
      
      // Then insert all sections
      const { error: insertError } = await supabase
        .from('rights_sections')
        .insert(sections);
      
      if (insertError) {
        console.error('Error inserting sections:', insertError);
        throw new Error('Failed to save rights sections. Please try again.');
      }
      
      setSuccessMessage('Rights sections saved successfully');
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error('Error saving rights sections:', err);
      setError(err instanceof Error ? err.message : 'Failed to save changes. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      navigate('/');
    } catch (err) {
      console.error('Error signing out:', err);
      setError('Failed to sign out');
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="text-gray-300 animate-pulse">Loading...</div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="text-gray-300">Please log in to access the rights editor.</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 p-8">
      <style>
        {`
          .responsive-video-container {
            position: relative;
            padding-bottom: 56.25%; /* 16:9 aspect ratio */
            height: 0;
            overflow: hidden;
            max-width: 100%;
            margin: 1rem 0;
          }
          
          .responsive-video-container iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        `}
      </style>
      <div className="max-w-6xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-white">Rights Editor</h1>
          <div className="flex items-center gap-4">
            <button
              onClick={handleSave}
              disabled={saving}
              className={`px-4 py-2 rounded-lg flex items-center gap-2 ${
                saving
                  ? 'bg-green-600/50 cursor-not-allowed'
                  : 'bg-green-600 hover:bg-green-700'
              } text-white`}
            >
              {saving ? (
                <>
                  <Cloud size={20} className="animate-bounce" />
                  <span>Saving...</span>
                </>
              ) : (
                <>
                  <Save size={20} />
                  <span>Save Changes</span>
                </>
              )}
            </button>
            <button
              onClick={handleLogout}
              className="px-4 py-2 bg-gray-800 text-gray-300 rounded-lg hover:bg-gray-700 flex items-center gap-2"
            >
              <LogOut size={20} />
              <span>Sign Out</span>
            </button>
          </div>
        </div>

        {error && (
          <div className="bg-red-900/50 text-red-100 px-4 py-3 rounded-lg mb-4 flex items-center gap-2">
            <AlertTriangle size={20} className="flex-shrink-0" />
            <span>{error}</span>
          </div>
        )}

        {successMessage && (
          <div className="bg-green-900/50 text-green-100 px-4 py-3 rounded-lg mb-4 flex items-center gap-2">
            <Check size={20} className="flex-shrink-0" />
            <span>{successMessage}</span>
          </div>
        )}

        <div className="space-y-6">
          {sections.map((section, index) => (
            <div
              key={section.id}
              className="bg-black/40 backdrop-blur-sm rounded-lg p-6"
            >
              <div className="flex items-center gap-4 mb-4">
                <GripVertical
                  size={20}
                  className="text-gray-500 cursor-move"
                />
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => moveSection(index, 'up')}
                    disabled={index === 0}
                    className={`p-2 rounded-lg ${
                      index === 0 
                        ? 'text-gray-600 cursor-not-allowed' 
                        : 'text-gray-400 hover:text-gray-300 hover:bg-gray-800'
                    }`}
                  >
                    <ChevronUp size={20} />
                  </button>
                  <button
                    onClick={() => moveSection(index, 'down')}
                    disabled={index === sections.length - 1}
                    className={`p-2 rounded-lg ${
                      index === sections.length - 1
                        ? 'text-gray-600 cursor-not-allowed'
                        : 'text-gray-400 hover:text-gray-300 hover:bg-gray-800'
                    }`}
                  >
                    <ChevronDown size={20} />
                  </button>
                </div>
                <div className="flex-1">
                  <span className="text-gray-400 text-sm">Section {index + 1}</span>
                </div>
                <button
                  onClick={() => deleteSection(index)}
                  className="p-2 text-red-400 hover:text-red-300 hover:bg-gray-800 rounded-lg transition-colors"
                >
                  <Trash2 size={20} />
                </button>
              </div>

              <div className="grid grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2">
                    English Title
                  </label>
                  <input
                    type="text"
                    value={section.title.en}
                    onChange={(e) => updateSection(index, 'title', 'en', e.target.value)}
                    className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-lg text-gray-100"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2">
                    Spanish Title
                  </label>
                  <input
                    type="text"
                    value={section.title.es}
                    onChange={(e) => updateSection(index, 'title', 'es', e.target.value)}
                    className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-lg text-gray-100"
                  />
                </div>

                <div>
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-300">
                      English Content
                    </label>
                    <div className="flex gap-2">
                      <button
                        title="Bold"
                        className="p-1 text-gray-400 hover:text-gray-300 hover:bg-gray-800 rounded"
                        onClick={() => {
                          const textarea = document.getElementById(`content-en-${index}`) as HTMLTextAreaElement;
                          if (textarea) {
                            const start = textarea.selectionStart;
                            const end = textarea.selectionEnd;
                            const text = textarea.value;
                            const before = text.substring(0, start);
                            const selection = text.substring(start, end);
                            const after = text.substring(end);
                            const newText = before + '**' + selection + '**' + after;
                            updateSection(index, 'content', 'en', newText.split('\n'));
                          }
                        }}
                      >
                        <Bold size={16} />
                      </button>
                      <button
                        title="Italic"
                        className="p-1 text-gray-400 hover:text-gray-300 hover:bg-gray-800 rounded"
                        onClick={() => {
                          const textarea = document.getElementById(`content-en-${index}`) as HTMLTextAreaElement;
                          if (textarea) {
                            const start = textarea.selectionStart;
                            const end = textarea.selectionEnd;
                            const text = textarea.value;
                            const before = text.substring(0, start);
                            const selection = text.substring(start, end);
                            const after = text.substring(end);
                            const newText = before + '*' + selection + '*' + after;
                            updateSection(index, 'content', 'en', newText.split('\n'));
                          }
                        }}
                      >
                        <Italic size={16} />
                      </button>
                      <button
                        title="Link"
                        className="p-1 text-gray-400 hover:text-gray-300 hover:bg-gray-800 rounded"
                        onClick={() => insertLink(index, 'en')}
                      >
                        <Link size={16} />
                      </button>
                      <button
                        title="YouTube Video"
                        className="p-1 text-gray-400 hover:text-gray-300 hover:bg-gray-800 rounded"
                        onClick={() => insertYoutubeEmbed(index, 'en')}
                      >
                        <Youtube size={16} />
                      </button>
                      <button
                        title="Bullet List"
                        className="p-1 text-gray-400 hover:text-gray-300 hover:bg-gray-800 rounded"
                        onClick={() => {
                          const textarea = document.getElementById(`content-en-${index}`) as HTMLTextAreaElement;
                          if (textarea) {
                            const start = textarea.selectionStart;
                            const end = textarea.selectionEnd;
                            const text = textarea.value;
                            const before = text.substring(0, start);
                            const selection = text.substring(start, end);
                            const after = text.substring(end);
                            const lines = selection.split('\n').map(line => '• ' + line).join('\n');
                            const newText = before + lines + after;
                            updateSection(index, 'content', 'en', newText.split('\n'));
                          }
                        }}
                      >
                        <List size={16} />
                      </button>
                    </div>
                  </div>
                  <textarea
                    id={`content-en-${index}`}
                    value={section.content.en.join('\n')}
                    onChange={(e) => updateSection(index, 'content', 'en', e.target.value.split('\n'))}
                    className="w-full h-64 px-3 py-2 bg-gray-800 border border-gray-700 rounded-lg text-gray-100 font-mono"
                  />
                </div>

                <div>
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-300">
                      Spanish Content
                    </label>
                    <div className="flex gap-2">
                      <button
                        title="Bold"
                        className="p-1 text-gray-400 hover:text-gray-300 hover:bg-gray-800 rounded"
                        onClick={() => {
                          const textarea = document.getElementById(`content-es-${index}`) as HTMLTextAreaElement;
                          if (textarea) {
                            const start = textarea.selectionStart;
                            const end = textarea.selectionEnd;
                            const text = textarea.value;
                            const before = text.substring(0, start);
                            const selection = text.substring(start, end);
                            const after = text.substring(end);
                            const newText = before + '**' + selection + '**' + after;
                            updateSection(index, 'content', 'es', newText.split('\n'));
                          }
                        }}
                      >
                        <Bold size={16} />
                      </button>
                      <button
                        title="Italic"
                        className="p-1 text-gray-400 hover:text-gray-300 hover:bg-gray-800 rounded"
                        onClick={() => {
                          const textarea = document.getElementById(`content-es-${index}`) as HTMLTextAreaElement;
                          if (textarea) {
                            const start = textarea.selectionStart;
                            const end = textarea.selectionEnd;
                            const text = textarea.value;
                            const before = text.substring(0, start);
                            const selection = text.substring(start, end);
                            const after = text.substring(end);
                            const newText = before + '*' + selection + '*' + after;
                            updateSection(index, 'content', 'es', newText.split('\n'));
                          }
                        }}
                      >
                        <Italic size={16} />
                      </button>
                      <button
                        title="Link"
                        className="p-1 text-gray-400 hover:text-gray-300 hover:bg-gray-800 rounded"
                        onClick={() => insertLink(index, 'es')}
                      >
                        <Link size={16} />
                      </button>
                      <button
                        title="YouTube Video"
                        className="p-1 text-gray-400 hover:text-gray-300 hover:bg-gray-800 rounded"
                        onClick={() => insertYoutubeEmbed(index, 'es')}
                      >
                        <Youtube size={16} />
                      </button>
                      <button
                        title="Bullet List"
                        className="p-1 text-gray-400 hover:text-gray-300 hover:bg-gray-800 rounded"
                        onClick={() => {
                          const textarea = document.getElementById(`content-es-${index}`) as HTMLTextAreaElement;
                          if (textarea) {
                            const start = textarea.selectionStart;
                            const end = textarea.selectionEnd;
                            const text = textarea.value;
                            const before = text.substring(0, start);
                            const selection = text.substring(start, end);
                            const after = text.substring(end);
                            const lines = selection.split('\n').map(line => '• ' + line).join('\n');
                            const newText = before + lines + after;
                            updateSection(index, 'content', 'es', newText.split('\n'));
                          }
                        }}
                      >
                        <List size={16} />
                      </button>
                    </div>
                  </div>
                  <textarea
                    id={`content-es-${index}`}
                    value={section.content.es.join('\n')}
                    onChange={(e) => updateSection(index, 'content', 'es', e.target.value.split('\n'))}
                    className="w-full h-64 px-3 py-2 bg-gray-800 border border-gray-700 rounded-lg text-gray-100 font-mono"
                  />
                </div>
              </div>

              <div className="mt-4">
                <label className="flex items-center gap-2 text-gray-300">
                  <input
                    type="checkbox"
                    checked={section.is_case_law || false}
                    onChange={(e) => updateSection(index, 'is_case_law', 'en', e.target.checked)}
                    className="rounded bg-gray-800 border-gray-700 text-blue-600 focus:ring-blue-500"
                  />
                  <span>This is a Case Law section (special formatting)</span>
                </label>
              </div>
            </div>
          ))}
        </div>

        <button
          onClick={addSection}
          className="mt-6 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center gap-2"
        >
          <Plus size={20} />
          <span>Add Section</span>
        </button>
      </div>
    </div>
  );
};

export default RightsEditor;