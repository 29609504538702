import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { translations } from '../translations';
import { supabase } from '../lib/supabase';

interface RightsSection {
  id: string;
  title: {
    en: string;
    es: string;
  };
  content: {
    en: string[];
    es: string[];
  };
  order: number;
  is_case_law?: boolean;
}

interface RightsProps {
  language?: 'en' | 'es';
}

const Rights = ({ language = 'en' }: RightsProps) => {
  const [expandedSection, setExpandedSection] = useState<number | null>(0); // Set to 0 to auto-expand Tia Maria
  const [sections, setSections] = useState<RightsSection[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const t = translations[language].rights;

  useEffect(() => {
    fetchRightsSections();
  }, []);

  const fetchRightsSections = async () => {
    try {
      setLoading(true);
      
      // Try to fetch from database
      const { data, error } = await supabase
        .from('rights_sections')
        .select('*')
        .order('order', { ascending: true });
      
      if (error) {
        console.error('Error fetching rights sections:', error);
        // Fall back to default sections
        setSections(getDefaultSections());
      } else if (data && data.length > 0) {
        // Format data from database
        const formattedSections = data.map(section => ({
          id: section.id,
          title: section.title,
          content: section.content,
          order: section.order,
          is_case_law: section.is_case_law
        }));
        setSections(formattedSections);
      } else {
        // No data, use defaults
        setSections(getDefaultSections());
      }
    } catch (err) {
      console.error('Error in fetchRightsSections:', err);
      setError('Failed to load rights sections. Using default content.');
      setSections(getDefaultSections());
    } finally {
      setLoading(false);
    }
  };

  const getDefaultSections = (): RightsSection[] => {
    return [
      {
        id: '1',
        title: {
          en: 'Tia Lupe - AI Assistant',
          es: 'Tía Lupe - Asistente de IA'
        },
        content: {
          en: [
            'You can get many of your questions answered by talking with this AI Chatbot. This chatbot is trained on information from <a href="https://lulac.org/" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">LULAC</a> and the <a href="https://www.aclu.org/" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">ACLU</a>. This ensures that the information you receive is the most up to date and correct.',
            '<elevenlabs-convai agent-id="nPjA5PlVWxRd7L1Ypou4"></elevenlabs-convai>'
          ],
          es: [
            'Puedes obtener respuestas a muchas de tus preguntas hablando con este chatbot de IA. Este chatbot está entrenado con información de <a href="https://lulac.org/" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">LULAC</a> y la <a href="https://www.aclu.org/" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">ACLU</a>. Esto garantiza que la información que recibas sea la más actualizada y precisa.',
            '<elevenlabs-convai agent-id="nPjA5PlVWxRd7L1Ypou4"></elevenlabs-convai>'
          ]
        },
        order: 0
      },
      {
        id: '2',
        title: {
          en: 'The Consular App',
          es: 'La Aplicación Consular'
        },
        content: {
          en: [
            'The government of Mexico has launched the mobile application ConsulApp Contigo, designed to provide support to Mexicans residing in the United States, especially those in vulnerable situations or facing possible immigration detentions. This tool allows users to quickly contact Mexican consulates and their family members in case of an emergency.',
            '',
            'Main Features of ConsulApp Contigo:',
            '• Emergency Button: By pressing it for five seconds, users can send an immediate alert to the nearest Mexican consulate and their emergency contacts, notifying them of a possible detention or critical situation.',
            '',
            '• Know Your Rights Information: The app offers a section titled "Know Your Rights", where it details the actions individuals can take during encounters with immigration agents, ensuring they are informed about their legal protections.',
            '',
            '• Consulate Locator: Includes an interactive map showing the locations of the 53 Mexican consular offices in the United States, making it easier for users to find and contact the nearest consulate.',
            '',
            '• Appointment Scheduling: Users can schedule appointments for consular services, such as obtaining passports or other official documents, directly through the app.',
            '',
            '<a href="https://apps.apple.com/us/app/consulapp-contigo/id6740634286" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">Download ConsulApp Contigo for iOS</a>',
            '',
            '<a href="https://play.google.com/store/apps/details?id=com.sre.consulapp&hl=en_US&pli=1" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">Download ConsulApp Contigo for Android</a>',
            '',
            '<div class="responsive-video-container"><iframe width="100%" height="315" src="https://www.youtube.com/embed/Atjpr1hSI08?si=8b5PVI2ktVMrhYL4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>'
          ],
          es: [
            'El gobierno de México ha lanzado la aplicación móvil ConsulApp Contigo, diseñada para brindar apoyo a los mexicanos que residen en Estados Unidos, especialmente aquellos en situaciones vulnerables o que enfrentan posibles detenciones migratorias. Esta herramienta permite a los usuarios contactar rápidamente a los consulados mexicanos y a sus familiares en caso de emergencia.',
            '',
            'Características principales de ConsulApp Contigo:',
            '',
            '• Botón de emergencia: Al presionarlo durante cinco segundos, el usuario puede enviar una alerta inmediata al consulado mexicano más cercano y a sus contactos de emergencia, notificando una posible detención o situación crítica.',
            '',
            '• Información sobre derechos: La aplicación ofrece una sección titulada "Conoce tus derechos", donde se detallan las acciones que las personas pueden tomar durante encuentros con agentes de inmigración, asegurando que estén informadas sobre sus garantías legales.',
            '',
            '• Localización de consulados: Incluye un mapa interactivo que muestra las ubicaciones de las 53 oficinas consulares mexicanas en Estados Unidos, facilitando a los usuarios encontrar y contactar el consulado más cercano.',
            '',
            '• Programación de citas: Los usuarios pueden agendar citas para servicios consulares, como la obtención de pasaportes u otros documentos oficiales, directamente desde la aplicación.',
            '',
            '<a href="https://apps.apple.com/us/app/consulapp-contigo/id6740634286" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">Pueden Obtener el App Aqui para iOS</a>',
            '',
            '<a href="https://play.google.com/store/apps/details?id=com.sre.consulapp&hl=en_US&pli=1" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">Pueden Obtener el App Aqui para Android</a>',
            '',
            '<div class="responsive-video-container"><iframe width="100%" height="315" src="https://www.youtube.com/embed/nM-dEiKZ0sk?si=9MoC0WHISKRYaW5W" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>'
          ]
        },
        order: 1
      },
      {
        id: '3',
        title: {
          en: t.sections.constitution.title,
          es: t.sections.constitution.title
        },
        content: {
          en: [
            'Its important to know that everyone in the United States is Protected by the U.S. Constitution— Citizen or not. The U.S. Constitution protects everyone that is physically in the U.S and is not meant for only citizens of the U.S. These rights ensure fair treatment for all.',
            '',
            'The U.S. Constitution has 27 amendments. The first ten amendments, known as the Bill of Rights, were ratified in 1791, and the most recent amendment, the 27th Amendment, was ratified in 1992. There are several of these amendments that are specific to protecting your rights:',
            '',
            'Fifth Amendment:',
            'Right to stay quiet if talking might get you in trouble.',
            'Everyone gets fair treatment by the law.',
            '',
            '**Fourth Amendment:**',
            '• Police need a warrant, a judge\'s permission, to search you or your things.',
            '• A warrant is different from an ICE order: A warrant comes from a judge, while an ICE order does not.',
            '• Protects your privacy, including immigrants.',
            '',
            '**Fourteenth Amendment:**',
            'Everyone deserves fair legal processes.',
            'Laws must treat everyone equally, giving the same rights to all.'
          ],
          es: [
            'Es importante saber que todas las personas en los Estados Unidos están protegidas por la Constitución de EE.UU., sin importar si son ciudadanos o no. La Constitución de EE.UU. protege a cualquier persona que se encuentre físicamente en el país y no está destinada únicamente a los ciudadanos. Estos derechos garantizan un trato justo para todos.',
            '',
            'La Constitución de los Estados Unidos tiene 27 enmiendas. Las primeras diez enmiendas, conocidas como la Carta de Derechos, fueron ratificadas en 1791, y la enmienda más reciente, la 27ª Enmienda, fue ratificada en 1992. Varias de estas enmiendas están diseñadas específicamente para proteger tus derechos:',
            '',
            'Cuarta Enmienda:',
            '• La policía necesita una orden judicial, el permiso de un juez, para registrarte a ti o tus pertenencias.',
            '• Una orden judicial es diferente de una orden de ICE: una orden judicial proviene de un juez, mientras que una orden de ICE no.',
            '• Protege tu privacidad, incluyendo a los inmigrantes.',
            '',
            'Quinta Enmienda:',
            '• Derecho a permanecer en silencio si hablar podría meterte en problemas.',
            '• Todas las personas tienen derecho a un trato justo bajo la ley.',
            '',
            'Decimocuarta Enmienda:',
            '• Todos merecen un proceso legal justo.',
            '• Las leyes deben tratar a todas las personas por igual, otorgando los mismos derechos a todos.'
          ]
        },
        order: 2
      },
      {
        id: '4',
        title: {
          en: t.sections.iceHome.title,
          es: t.sections.iceHome.title
        },
        content: {
          en: [
            '• You have the right to remain silent and do not have to discuss your immigration status',
            '• Ask if they have a warrant signed by a judge. If they don\'t, you can refuse to let them in',
            '• Stay calm and keep the door closed',
            '• If they have a valid warrant, you may have to let them in, but you still have the right to remain silent and consult a lawyer',
            '• Do not sign any documents without speaking to an attorney'
          ],
          es: [
            '• Tienes derecho a guardar silencio y no tienes que discutir tu estatus migratorio',
            '• Pregunta si tienen una orden judicial firmada por un juez. Si no la tienen, puedes negarte a dejarlos entrar',
            '• Mantén la calma y mantén la puerta cerrada',
            '• Si tienen una orden válida, puede que tengas que dejarlos entrar, pero aún tienes derecho a guardar silencio y consultar a un abogado',
            '• No firmes ningún documento sin hablar con un abogado'
          ]
        },
        order: 3
      },
      {
        id: '5',
        title: {
          en: t.sections.iceStreet.title,
          es: t.sections.iceStreet.title
        },
        content: {
          en: [
            '• You have the right to remain silent and do not have to discuss your immigration status',
            '• Ask if you are free to leave. If they say yes, calmly walk away',
            '• You have the right to refuse to show identification or answer questions',
            '• Remember, you do not have to sign any documents without speaking to an attorney'
          ],
          es: [
            '• Tienes derecho a guardar silencio y no tienes que discutir tu estatus migratorio',
            '• Pregunta si eres libre de irte. Si dicen que sí, aléjate calmadamente',
            '• Tienes derecho a negarte a mostrar identificación o responder preguntas',
            '• Recuerda, no tienes que firmar ningún documento sin hablar con un abogado'
          ]
        },
        order: 4
      },
      {
        id: '6',
        title: {
          en: t.sections.caseLaw.title,
          es: t.sections.caseLaw.title
        },
        content: {
          en: [
            'Yick Wo v. Hopkins (1886)',
            'Established that the Equal Protection Clause of the 14th Amendment applies to all persons, not just citizens, reinforcing constitutional protections for immigrants.',
            '<a href="https://en.wikipedia.org/wiki/Yick_Wo_v._Hopkins" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">Learn more</a>',
            '',
            'Zadvydas v. Davis (2001)',
            'Limited the government\'s power to indefinitely detain immigrants who have been ordered removed but cannot be deported.',
            '<a href="https://en.wikipedia.org/wiki/Zadvydas_v._Davis" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">Learn more</a>',
            '',
            'Plyler v. Doe (1982)',
            'Established that states cannot deny public education to undocumented immigrant children.',
            '<a href="https://en.wikipedia.org/wiki/Plyler_v._Doe" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">Learn more</a>'
          ],
          es: [
            'Yick Wo v. Hopkins (1886)',
            'Estableció que la Cláusula de Protección Igualitaria de la 14ª Enmienda se aplica a todas las personas, no solo a los ciudadanos, reforzando las protecciones constitucionales para inmigrantes.',
            '<a href="https://en.wikipedia.org/wiki/Yick_Wo_v._Hopkins" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">Más información</a>',
            '',
            'Zadvydas v. Davis (2001)',
            'Limitó el poder del gobierno para detener indefinidamente a inmigrantes que han recibido orden de deportación pero no pueden ser deportados.',
            '<a href="https://en.wikipedia.org/wiki/Zadvydas_v._Davis" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">Más información</a>',
            '',
            'Plyler v. Doe (1982)',
            'Estableció que los estados no pueden negar educación pública a niños inmigrantes indocumentados.',
            '<a href="https://en.wikipedia.org/wiki/Plyler_v._Doe" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:text-blue-300">Más información</a>'
          ]
        },
        order: 5,
        is_case_law: true
      }
    ];
  };

  const toggleSection = (index: number) => {
    setExpandedSection(current => current === index ? null : index);
  };

  const renderCaseLawContent = (content: string[]) => {
    const cases = [];
    let currentCase = {
      title: '',
      description: '',
      learnMore: ''
    };

    for (let i = 0; i < content.length; i++) {
      const line = content[i].trim();
      
      if (!line) continue;

      if (line.includes('v.') && /\(\d{4}\)/.test(line)) {
        if (currentCase.title) {
          cases.push({ ...currentCase });
        }
        currentCase = {
          title: line,
          description: '',
          learnMore: ''
        };
      }
      else if (line.startsWith('<a href=') && (line.includes('Learn more') || line.includes('Más información'))) {
        currentCase.learnMore = line;
      }
      else if (currentCase.title) {
        currentCase.description = line;
      }
    }

    if (currentCase.title) {
      cases.push(currentCase);
    }

    return (
      <div className="space-y-6">
        {cases.map((caseItem, index) => (
          <div key={index} className="space-y-2">
            <h3 className="CaseLaw_Emphasis">
              {caseItem.title}
            </h3>
            <p className="text-gray-300">
              {caseItem.description}
            </p>
            {caseItem.learnMore && (
              <div dangerouslySetInnerHTML={{ __html: caseItem.learnMore }} />
            )}
          </div>
        ))}
      </div>
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="text-gray-300 animate-pulse">Loading...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900">
      {error && (
        <div className="max-w-4xl mx-auto px-6 pt-4">
          <div className="bg-red-900/50 text-red-100 px-4 py-3 rounded-lg mb-4">
            {error}
          </div>
        </div>
      )}
      <div className="max-w-4xl mx-auto px-6 py-8 space-y-4">
        {sections.map((section, index) => {
          // Skip rendering ElevenLabs widget
          if (section.title[language].includes('Tía Lupe')) {
            return null;
          }
          return (
          <div
            key={section.id}
            className="bg-black/40 backdrop-blur-sm rounded-lg overflow-hidden"
          >
            <button
              onClick={() => toggleSection(index)}
              className="w-full px-6 py-4 flex items-center justify-between text-white hover:bg-black/50 transition-colors"
            >
              <span className="text-xl font-bold">{section.title[language]}</span>
              {expandedSection === index ? (
                <ChevronDown size={24} />
              ) : (
                <ChevronRight size={24} />
              )}
            </button>
            
            {expandedSection === index && (
              <div className="px-6 pb-6">
                {section.is_case_law ? (
                  renderCaseLawContent(section.content[language])
                ) : (
                  <div className="space-y-4">
                    {section.content[language].map((text, i) => (
                      <div key={i} className="text-gray-300" dangerouslySetInnerHTML={{ __html: text }} />
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        )})}
      </div>
    </div>
  );
};

export default Rights;