import React, { useState, useRef, useEffect } from 'react';
import { Mic, Play, Square, Copy, AlertTriangle, Database, Share2, Clock, MapPin } from 'lucide-react';
import { translations } from '../translations';
import { supabase, testSupabaseConnection } from '../lib/supabase';
import { v4 as uuidv4 } from 'uuid';
import { redCardStatements } from '../lib/audioStatements';
import AudioPlayer from './AudioPlayer';

interface RedCardTestProps {
  language?: 'en' | 'es';
}

const RedCardTest = ({ language = 'en' }: RedCardTestProps) => {
  const [isRecording, setIsRecording] = useState(false);
  const [recordings, setRecordings] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [location, setLocation] = useState<{ lat: number; lng: number } | null>(null);
  const [sessionId] = useState(() => uuidv4());
  const [isSupabaseConnected, setIsSupabaseConnected] = useState(false);
  const [isCheckingConnection, setIsCheckingConnection] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [copiedId, setCopiedId] = useState<string | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const chunksRef = useRef<Blob[]>([]);
  const audioContextRef = useRef<AudioContext | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const t = translations[language];

  const checkSupabaseConnection = async () => {
    try {
      setIsCheckingConnection(true);
      const isConnected = await testSupabaseConnection();
      setIsSupabaseConnected(isConnected);
      setError(null);
      
      if (isConnected) {
        await fetchRecordings();
      }
    } catch (err) {
      console.error('Supabase connection error:', err);
      setIsSupabaseConnected(false);
      setError(language === 'es' 
        ? 'Error de conexión a la base de datos. Por favor, conecte a Supabase usando el botón "Connect to Supabase".'
        : 'Database connection error. Please connect to Supabase using the "Connect to Supabase" button.');
    } finally {
      setIsCheckingConnection(false);
    }
  };

  useEffect(() => {
    checkSupabaseConnection();
    
    // Get user's location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
        },
        (err) => {
          console.error('Location error:', err);
          setError(language === 'es' 
            ? 'No se pudo obtener la ubicación'
            : 'Could not get location');
        }
      );
    }

    // Initialize AudioContext
    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();

    // Cleanup function
    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  const fetchRecordings = async () => {
    try {
      const { data, error } = await supabase
        .from('recordings')
        .select('*')
        .eq('session_id', sessionId)
        .order('created_at', { ascending: false });

      if (error) throw error;
      if (data) setRecordings(data);
    } catch (err) {
      console.error('Error fetching recordings:', err);
      setError(language === 'es'
        ? 'Error al cargar las grabaciones'
        : 'Error loading recordings');
    }
  };

  const getSupportedMimeType = () => {
    const types = [
      'audio/webm',
      'audio/webm;codecs=opus',
      'audio/ogg;codecs=opus',
      'audio/mp4',
      'audio/mpeg',
      'audio/wav'
    ];
    
    for (const type of types) {
      if (MediaRecorder.isTypeSupported(type)) {
        return type;
      }
    }
    return null;
  };

  const startRecording = async () => {
    if (!isSupabaseConnected) {
      setError(language === 'es'
        ? 'Por favor, conecte a Supabase primero'
        : 'Please connect to Supabase first');
      return;
    }

    try {
      // Request microphone access with specific constraints
      const stream = await navigator.mediaDevices.getUserMedia({ 
        audio: {
          echoCancellation: false,
          noiseSuppression: false,
          autoGainControl: false,
          channelCount: 1,
          sampleRate: 44100
        } 
      });

      streamRef.current = stream;

      // Connect the stream to the AudioContext
      if (audioContextRef.current) {
        const source = audioContextRef.current.createMediaStreamSource(stream);
        const destination = audioContextRef.current.createMediaStreamDestination();
        source.connect(destination);
      }

      const mimeType = getSupportedMimeType();
      if (!mimeType) {
        throw new Error('No supported audio MIME type found');
      }

      const mediaRecorder = new MediaRecorder(stream, {
        mimeType,
        audioBitsPerSecond: 128000
      });
      
      mediaRecorderRef.current = mediaRecorder;
      chunksRef.current = [];

      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunksRef.current.push(e.data);
        }
      };

      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(chunksRef.current, { type: mimeType });
        await saveRecording(audioBlob);
        
        if (streamRef.current) {
          streamRef.current.getTracks().forEach(track => track.stop());
          streamRef.current = null;
        }
      };

      mediaRecorder.start(1000);
      setIsRecording(true);
      setError(null);
    } catch (err) {
      console.error('Recording error:', err);
      setError(language === 'es'
        ? 'Error al iniciar la grabación. Por favor, verifique los permisos del micrófono.'
        : 'Error starting recording. Please check microphone permissions.');
      setIsRecording(false);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const saveRecording = async (audioBlob: Blob) => {
    try {
      setIsSaving(true);
      const extension = audioBlob.type.includes('mp4') ? 'mp4' : 
                       audioBlob.type.includes('wav') ? 'wav' :
                       audioBlob.type.includes('aac') ? 'aac' : 'webm';
                       
      const fileName = `${uuidv4()}.${extension}`;
      const { data: uploadData, error: uploadError } = await supabase.storage
        .from('recordings')
        .upload(fileName, audioBlob);

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from('recordings')
        .getPublicUrl(fileName);

      const { error: dbError } = await supabase
        .from('recordings')
        .insert({
          recording_url: fileName,
          location: location ? `${location.lat},${location.lng}` : null,
          public_url: publicUrl,
          session_id: sessionId
        });

      if (dbError) throw dbError;

      await fetchRecordings();
      setError(null);
    } catch (err) {
      console.error('Save recording error:', err);
      setError(language === 'es'
        ? 'Error al guardar la grabación'
        : 'Error saving recording');
    } finally {
      setIsSaving(false);
    }
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString(
      language === 'es' ? 'es-ES' : 'en-US',
      {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      }
    );
  };

  const copyRecordingLink = async (recording: any) => {
    try {
      await navigator.clipboard.writeText(recording.public_url);
      setCopiedId(recording.id);
      setTimeout(() => setCopiedId(null), 2000);
    } catch (err) {
      console.error('Copy error:', err);
      setError(language === 'es'
        ? 'Error al copiar el enlace'
        : 'Error copying link');
    }
  };

  const shareToWhatsApp = (recording: any) => {
    const text = language === 'es'
      ? `Grabación de audio de VÍGIA: ${recording.public_url}`
      : `VÍGIA audio recording: ${recording.public_url}`;
    
    const url = `https://wa.me/?text=${encodeURIComponent(text)}`;
    window.open(url, '_blank');
  };

  if (isCheckingConnection) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="text-gray-300 animate-pulse">
          {language === 'es' ? 'Verificando conexión...' : 'Checking connection...'}
        </div>
      </div>
    );
  }

  if (!isSupabaseConnected) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center px-4">
        <div className="bg-black/40 backdrop-blur-sm rounded-lg p-8 max-w-md w-full">
          <div className="flex justify-center mb-6 text-blue-500">
            <Database size={48} />
          </div>
          <h2 className="text-2xl font-bold text-white mb-4 text-center">
            {language === 'es' ? 'Conexión Requerida' : 'Connection Required'}
          </h2>
          <p className="text-gray-300 text-center mb-6">
            {language === 'es'
              ? 'Por favor, conecte a Supabase usando el botón "Connect to Supabase" en la esquina superior derecha.'
              : 'Please connect to Supabase using the "Connect to Supabase" button in the top right corner.'}
          </p>
          <div className="flex justify-center">
            <button
              onClick={checkSupabaseConnection}
              className="px-6 py-3 bg-blue-600 text-white rounded-xl hover:bg-blue-700 transition-colors text-lg font-medium"
            >
              {language === 'es' ? 'Reintentar Conexión' : 'Retry Connection'}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#330000]">
      <div className="max-w-2xl mx-auto px-6 py-8 space-y-8">
        <div className="bg-[#660000] backdrop-blur-sm rounded-lg p-6">
          <div className="flex flex-col gap-2">
            <h1 className="text-2xl font-bold text-white text-center">
              {language === 'es' ? 'Tarjeta Roja Digital (Test)' : 'Digital Red Card (Test)'}
            </h1>
            <button
              onClick={isRecording ? stopRecording : startRecording}
              disabled={isSaving}
              className="w-full px-6 py-3 bg-[#993333] hover:bg-[#993333]/90 text-white rounded-lg font-medium flex items-center justify-center gap-2"
            >
              {isRecording ? (
                <>
                  <Square size={20} />
                  <span className="whitespace-nowrap">{language === 'en' ? 'Stop Recording' : 'Detener Grabación'}</span>
                </>
              ) : (
                <>
                  <Play size={20} />
                  <span className="whitespace-nowrap">{isSaving ? (language === 'en' ? 'Saving...' : 'Guardando...') : (language === 'en' ? 'Click to Start Recording' : 'Haga Clic para Iniciar Grabación')}</span>
                </>
              )}
            </button>
          </div>

          {error && (
            <div className="mt-4 bg-red-900/50 text-red-100 px-4 py-3 rounded-lg flex items-center gap-2">
              <AlertTriangle size={20} className="flex-shrink-0" />
              <span>{error}</span>
            </div>
          )}
        </div>

        <div className="bg-[#660000] backdrop-blur-sm rounded-lg p-6">
          <h2 className="text-xl font-bold text-white mb-6">
            {language === 'en' ? 'Pre-recorded Responses' : 'Respuestas Pregrabadas'}
          </h2>
          <p className="text-gray-200 font-medium mb-4">
            {language === 'en'
              ? 'Click the buttons below to play pre-recorded responses in English.'
              : 'Haga clic en los botones para reproducir respuestas pregrabadas en inglés.'}
          </p>
          <AudioPlayer 
            speakerMode={true} 
            language={language}
            useRedCardStatements={true}
          />
        </div>

        {recordings.length > 0 && (
          <div className="bg-[#660000] backdrop-blur-sm rounded-lg p-6">
            <h2 className="text-xl font-bold text-white mb-6">
              {language === 'en' ? 'Saved Recordings' : 'Grabaciones Guardadas'}
            </h2>
            
            <div className="space-y-4">
              {recordings.map((recording) => (
                <div
                  key={recording.id}
                  className="bg-black/30 backdrop-blur-sm rounded-lg p-4 hover:bg-black/40 transition-colors"
                >
                  <div className="flex flex-col gap-4">
                    <div className="flex items-start justify-between">
                      <div className="space-y-1">
                        <div className="flex items-center gap-2 text-gray-300 text-sm">
                          <Clock size={16} className="flex-shrink-0" />
                          <span>{formatDate(recording.created_at)}</span>
                        </div>
                        {recording.location && (
                          <div className="flex items-center gap-2 text-gray-400 text-sm">
                            <MapPin size={16} className="flex-shrink-0" />
                            <span>{recording.location}</span>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="flex items-center gap-2">
                      <audio
                        src={recording.public_url}
                        controls
                        className="flex-1 h-8"
                        onError={(e) => {
                          console.error('Audio playback error:', e);
                          setError(language === 'es'
                            ? 'Error al reproducir el audio'
                            : 'Error playing audio');
                        }}
                      />
                      <button
                        onClick={() => copyRecordingLink(recording)}
                        className={`p-2 rounded-lg transition-colors ${
                          copiedId === recording.id
                            ? 'bg-green-600 text-white'
                            : 'text-gray-400 hover:text-gray-300 hover:bg-gray-800'
                        }`}
                        title={language === 'en' ? 'Copy Link' : 'Copiar Enlace'}
                      >
                        {copiedId === recording.id ? (
                          <span className="text-sm px-1">✓</span>
                        ) : (
                          <Copy size={20} />
                        )}
                      </button>
                      <button
                        onClick={() => shareToWhatsApp(recording)}
                        className="p-2 text-gray-400 hover:text-gray-300 hover:bg-gray-800 rounded-lg transition-colors"
                        title={language === 'en' ? 'Share on WhatsApp' : 'Compartir en WhatsApp'}
                      >
                        <Share2 size={20} />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RedCardTest;