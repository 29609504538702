import React, { useEffect } from 'react';
import { translations } from '../translations';

interface FooterProps {
  language?: 'en' | 'es';
  className?: string;
}

const Footer = ({ language = 'en', className = '' }: FooterProps) => {
  return (
    <footer className={`fixed bottom-0 left-0 right-0 bg-black/90 backdrop-blur-sm border-t border-gray-800 z-40 ${className}`}>
      <div className="container mx-auto px-4 py-4 flex items-center justify-between gap-4">
        <div className="flex-1 overflow-hidden whitespace-nowrap">
          <div className="animate-scroll inline-block">
            <p className="text-gray-300 text-sm">
              {language === 'es' 
                ? <>Este sitio web es <span className="text-yellow-400">gratuito</span>, <span className="text-yellow-400">anónimo</span> y <span className="text-yellow-400">no necesitas iniciar sesión</span>.</>
                : <>This website is <span className="text-yellow-400">free to use</span>, <span className="text-yellow-400">anonymous</span>, and you <span className="text-yellow-400">don't need to sign in</span>.</>
              }
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;