import React, { useState, useEffect, useRef } from 'react';
import { Conversation } from '@11labs/client';
import { AlertTriangle } from 'lucide-react';

interface LupeTestProps {
  language?: 'en' | 'es';
}

const LupeTest = ({ language = 'en' }: LupeTestProps) => {
  const [isConnected, setIsConnected] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const conversationRef = useRef<any>(null);

  const requestMicrophonePermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      setError(null);
      return true;
    } catch (error) {
      console.error('Microphone permission denied:', error);
      setError('Microphone permission is required for the conversation.');
      return false;
    }
  };

  const startConversation = async () => {
    try {
      const hasPermission = await requestMicrophonePermission();
      if (!hasPermission) {
        return;
      }

      conversationRef.current = await Conversation.startSession({
        agentId: 'nPjA5PlVWxRd7L1Ypou4',
        onConnect: () => {
          console.log('Connected');
          setIsConnected(true);
          setError(null);
        },
        onDisconnect: () => {
          console.log('Disconnected');
          setIsConnected(false);
          setIsSpeaking(false);
          setError(null);
        },
        onError: (error) => {
          console.error('Conversation error:', error);
          setError('An error occurred during the conversation.');
        },
        onModeChange: (mode) => {
          setIsSpeaking(mode.mode === 'speaking');
        }
      });
    } catch (error) {
      console.error('Error starting conversation:', error);
      setError('Failed to start conversation. Please try again.');
    }
  };

  const endConversation = async () => {
    if (conversationRef.current) {
      await conversationRef.current.endSession();
      conversationRef.current = null;
    }
  };

  useEffect(() => {
    return () => {
      if (conversationRef.current) {
        conversationRef.current.endSession();
      }
    };
  }, []);

  return (
    <div className="min-h-screen bg-gray-900 px-4">
      <div className="max-w-2xl mx-auto pt-4 text-left">
        <h1 className="text-2xl font-bold text-white mb-4 text-center">Talk to Tía Lupe</h1>

        <div className="text-gray-300 mb-4">
          <p>
            Tía Lupe is an AI assistant trained on information from{' '}
            <a href="https://lulac.org/" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
              LULAC
            </a> and the{' '}
            <a href="https://www.aclu.org/" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
              ACLU
            </a>. She can answer questions about your rights, immigration processes, and legal protections in the United States.
          </p>
        </div>

        <div className="bg-black/40 backdrop-blur-sm rounded-lg overflow-hidden shadow-lg border border-gray-700 mb-4">
          {error && (
            <div className="bg-red-900/50 text-red-100 px-4 py-3 flex items-center gap-2">
              <AlertTriangle size={20} className="flex-shrink-0" />
              <span>{error}</span>
            </div>
          )}

          {/* Main Content Container */}
          <div className="relative">
            {/* Status Indicators - Top Right */}
            <div className="absolute top-4 right-4 z-10 flex flex-col gap-2">
              <div
                className={`px-3 py-1.5 rounded-lg text-xs font-medium shadow-lg ${
                  isConnected
                    ? 'bg-green-600 text-white'
                    : 'bg-red-600 text-white'
                }`}
              >
                {isConnected 
                  ? language === 'en' ? 'Connected' : 'Conectado'
                  : language === 'en' ? 'Disconnected' : 'Desconectado'
                }
              </div>
              <div
                className={`px-3 py-1.5 rounded-lg text-xs font-medium shadow-lg ${
                  isSpeaking
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-700 text-gray-100'
                }`}
              >
                {isSpeaking
                  ? language === 'en' ? 'Agent Speaking' : 'Agente Hablando'
                  : language === 'en' ? 'Agent Silent' : 'Agente en Silencio'
                }
              </div>
            </div>
            {/* Image Container */}
            <div className="relative w-full aspect-[4/3] md:aspect-[3/2] rounded-xl overflow-hidden shadow-2xl">
              <img
                src="/tia_lupe_w.jpg"
                alt="AI Assistant Avatar"
                className={`w-full h-full object-cover transition-transform duration-300 ${
                  isSpeaking ? 'scale-[1.02]' : ''
                }`}
              />
              <div
                className={`absolute bottom-4 right-4 w-4 h-4 rounded-full border-2 border-white shadow-lg transition-colors duration-300 ${
                  isSpeaking ? 'bg-blue-500' : 'bg-gray-300'
                }`}
              />
            </div>

            {/* Control Buttons - Bottom */}
            <div className="absolute -bottom-4 left-0 right-0 flex justify-center gap-2 mb-8">
              <button
                onClick={startConversation}
                disabled={isConnected}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-all shadow-lg ${
                  isConnected
                    ? 'bg-gray-600 cursor-not-allowed'
                    : 'bg-green-600 hover:bg-green-500'
                } text-white`}
              >
                {language === 'en' ? 'Start Conversation' : 'Iniciar Conversación'}
              </button>
              <button
                onClick={endConversation}
                disabled={!isConnected}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-all shadow-lg ${
                  !isConnected
                    ? 'bg-gray-600 cursor-not-allowed'
                    : 'bg-red-600 hover:bg-red-500'
                } text-white`}
              >
                {language === 'en' ? 'End Conversation' : 'Terminar Conversación'}
              </button>
            </div>
            <div className="pb-8"></div> {/* Extra padding to ensure buttons are visible */}
          </div>
        </div>

        <div className="text-gray-300 space-y-4">
          <ul className="list-disc pl-6 space-y-2">
            <li>What are my rights if ICE comes to my door?</li>
            <li>Do I have to show identification to police?</li>
            <li>What is the difference between a warrant and an ICE order?</li>
            <li>What should I do if I'm detained?</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LupeTest;